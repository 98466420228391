html, body, #root {
    height: 100%;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.App-link {
  color: #61dafb;
}

.server-name, .server-id, .server-state, .switch-button {
    display: inline-block;
    padding: 5px;
    margin: 0;
}

ul {
    list-style-type: none; /* Remove bullets */
    padding: 0;
    margin: 0;
}



.federated-sign-in-container {
    padding: 0 !important;
}

.federated-sign-in-container + div, [data-amplify-footer],
[data-label="or"] {
    display: none;
}

.server-record {
    color: white;
    background: #7A7A7A;
    margin: 1rem;
    padding: 1rem;
}

.server-button {
    display: inline-block;
}

.server-container {
    height: 100%;
    margin: 2rem;
    display: flex;
    justify-content: center;
    align-items: stretch;
}

body {
    background: #00517e;
}

.vintra-logo, .vintra-title {
    /* display: inline-block; */
    color: #61dafb;
    margin-bottom: 10px;
}

.vintra-title { 

}

.beatloader {
    display: inline-block;
}

.header {
    display : flex;
    align-items : end;
    background-color: #282c34;
    font-size: calc(10px + 2vmin);
    color: white;
    -webkit-align-items: end;
  /* text-align: center; */
}

.sign-out {
    margin-top: 10px;
    margin-right: 10px;
    align-self: start;
    margin-left: auto;
}

/* [data-amplify-authenticator-signin] { */
/*     padding-top: 16px; */
/* } */

[data-amplify-router-content] > div {
    background: #7A7A7A;
    /* background: #292e31; */
}

.vintra-header {
    width: 100%;
}

.federated-sign-in-button {
    background-color: white;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
